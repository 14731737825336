import { Dispatch } from 'redux';
import api from '../../utils/service';
import { DealerDetail } from './data';
import { notifSend } from './notifs';
import { loadingCycle } from './page';

// Not connected to redux store yet because no state is used, only actions.

export const createNewDealer = data => async dispatch => {
  try {
    const { data: responseData } = await loadingCycle(
      api.post('/dealers/new', { ...data, hasNewProduct: true }),
    );
    dispatch(
      notifSend({
        kind: 'success',
        message: `${responseData.dealershipName} was created successfully, forms have been submitted for signature`,
        dismissAfter: 5000,
      }),
    );
    return responseData;
  } catch (err) {
    const { response } = err;
    dispatch(
      notifSend({
        kind: 'danger',
        message: `Failed to create dealer: ${(response.data && response.data.message) ||
          err.toString()}`,
        dismissAfter: 6000,
      }),
    );
    throw err;
  }
};

export const createDealerProductUpgradeRequest = data => async dispatch => {
  try {
    const { data: responseData } = await loadingCycle(api.post('/dealers/upgrade', data));
    dispatch(
      notifSend({
        kind: 'success',
        message: `${responseData.dealershipName} was created successfully, forms have been submitted for signature`,
        dismissAfter: 5000,
      }),
    );
    return responseData;
  } catch (err) {
    const { response } = err;
    dispatch(
      notifSend({
        kind: 'danger',
        message: `Failed to create dealer: ${(response && response.data && response.data.message) ||
          err.toString()}`,
        dismissAfter: 6000,
      }),
    );
    throw err;
  }
};

export const createDealerUpdateRequest = data => async dispatch => {
  try {
    const { data: responseData } = await loadingCycle(
      api.post('/dealers/update_dealer', { ...data, hasNewProduct: true }),
    );
    dispatch(
      notifSend({
        kind: 'success',
        message: `${responseData.dealershipName} was updated successfully, forms have been submitted for signature`,
        dismissAfter: 5000,
      }),
    );
    return responseData;
  } catch (err) {
    const { response } = err;
    dispatch(
      notifSend({
        kind: 'danger',
        message: `Failed to update dealer: ${(response.data && response.data.message) ||
          err.toString()}`,
        dismissAfter: 6000,
      }),
    );
    throw err;
  }
};

export const newDealerNameVerify = async (dealershipName: string) =>
  (await api.post('/dealers/verifyName', { dealershipName })).data.unique as boolean;

export interface DealerStatementsData {
  details: {
    dealerId: string;
    dlCompany: string;
  };
  statements: {}[];
}

export const getDealerStatements = ({ dealerId }) => async dispatch => {
  try {
    const {
      data: { details, statements },
    } = await loadingCycle(api.get(`/dealers/${dealerId}/statements`));
    return { details, statements } as DealerStatementsData;
  } catch (err) {
    const { response } = err;
    dispatch(
      notifSend({
        kind: 'danger',
        message: `Failed to get dealer statements: ${(response &&
          response.data &&
          response.data.message) ||
          err.toString()}`,
        dismissAfter: 6000,
      }),
    );
    throw err;
  }
};

export interface DealersImportResult {
  intersections?: {
    prcoDealerId: number;
    rowIndex: number;
    DLCompany?: string;
  }[];
  upsertResult?: any;
  error?: {
    message: string;
  };
}
export const dealersImport = ({
  file,
  confirmOverwrite,
}: {
  file: File;
  confirmOverwrite: boolean;
}) => async (dispatch: Dispatch): Promise<DealersImportResult> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    if (confirmOverwrite) formData.append('confirmOverwrite', 'confirmOverwrite');
    return await loadingCycle(
      api.post(`/dealers/import`, formData).then(response => response.data as DealersImportResult),
    );
  } catch (err) {
    const { response } = err;
    notifSend({
      kind: 'danger',
      message: `Failed to import dealers: ${(response && response.data && response.data.message) ||
        err.toString()}`,
      dismissAfter: 6000,
    })(dispatch);
    throw err;
  }
};

export const isDealerHasNoContractEffective = (dealerDetails: DealerDetail) =>
  dealerDetails.gapEff === '0000-00-00' &&
  dealerDetails.vscEff === '0000-00-00' &&
  dealerDetails.twpEff === '0000-00-00';
