import React, { InputHTMLAttributes, useEffect } from 'react';
import { Checkbox, Col, FormGroup, Row } from 'react-bootstrap';
import { FieldArrayRenderProps } from 'formik';
import InputError, { getFieldValidationState } from './InputError';

export type InputProps<V = string[]> = FieldArrayRenderProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'children' | 'onChange'> & {
    label: string;
    options: V;
    onChange(selectedOptions: V): void;
  };

export default function ValidationMultiCheckbox<V>({
  label,
  options,
  form: { errors, touched, setFieldTouched, values },
  name,
  push,
  remove,
  style,
  onChange,
  ...inputProps
}: InputProps<V[]>) {
  const value = values[name];
  const fieldError = errors[name];
  const fieldTouched = touched[name];
  const [selectedOptions, setOptions] = React.useState<V[]>(value ?? []);

  useEffect(() => {
    if (value != null) {
      // update state on prop change
      setOptions(value);
    }
    // eslint-disable-next-line
  }, [`${value}`, `${selectedOptions}`]);

  const handleOptionChange = (selectedOption: V) => {
    const selectedIndex = selectedOptions.indexOf(selectedOption);
    if (selectedIndex >= 0) {
      remove(selectedIndex);
      onChange?.(selectedOptions.filter(i => i !== selectedOption));
      return;
    }
    onChange?.(selectedOptions.concat(selectedOption));
    push(selectedOption);
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  return (
    <Row>
      <Col xs={3}>
        <label htmlFor={name}>{label}</label>
      </Col>
      <Col xs={9}>
        <FormGroup
          controlId={name}
          validationState={getFieldValidationState({ fieldTouched, fieldError })}
        >
          {options.map((option, index) => (
            <Checkbox
              data-testid={`${label}:${option}`}
              key={index}
              inline
              checked={selectedOptions.includes(option)}
              onChange={() => handleOptionChange(option)}
              onBlur={() => handleBlur()}
              className="form-check-input"
              type="checkbox"
              disabled={inputProps.disabled}
            >
              {option}
            </Checkbox>
          ))}
          <InputError fieldName={name} />
        </FormGroup>
      </Col>
    </Row>
  );
}
