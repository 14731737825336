import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { Alert, Button, Checkbox, Col, Grid, Row } from 'react-bootstrap';
import { connect, DispatchProp } from 'react-redux';
import Dropzone, { DropFilesEventHandler } from 'react-dropzone';
import { dealersImport, DealersImportResult } from '../../redux/modules/dealer';
import './Import.css';

const ACCEPTED_MIME_TYPES = ['text/csv'].join(',');

export const DealersImport: FunctionComponent<DispatchProp> = props => {
  const { dispatch } = props;

  const [confirmOverwrite, setConfirmOverwrite] = useState(false);
  const [response, setResponse] = useState<DealersImportResult>();
  const [intersectionsSelection, setIntersectionsSelection] = useState('data');

  const onDrop = useCallback<DropFilesEventHandler>(
    async ([file]) => {
      setResponse(await dealersImport({ file, confirmOverwrite })(dispatch));
    },
    [dispatch, confirmOverwrite],
  );

  let content: ReactNode;

  if (response) {
    const { intersections = [], upsertResult, error } = response;

    content = [
      !!error && (
        <Alert bsStyle="danger">
          Error occurred during the operation:
          <p style={{ background: 'white' }}>{error.message || 'Unknown error'}</p>
          <p>
            <Button onClick={() => setResponse(undefined)}>Start over</Button>
          </p>
        </Alert>
      ),
      <div key="alert" style={{ position: 'sticky', top: 0 }}>
        {upsertResult?.ok && <Alert bsStyle="success">Successful imported</Alert>}
        {!!upsertResult && !upsertResult?.ok && (
          <Alert bsStyle="danger">Import operation failed</Alert>
        )}
        {intersections.length > 0 && (
          <Alert bsStyle="warning">
            <h4>Operation were aborted.</h4>
            <p>We found some dealer records in your file, which already exist in DB.</p>
            <p>To continue, either upload file with only new dealers, or confirm overwrite.</p>
            <p>
              <Button onClick={() => setResponse(undefined)}>Start over</Button>
            </p>
          </Alert>
        )}
      </div>,
      intersections.length > 0 && (
        <div key="intersections">
          <h4>Dealers records in DB already exist for these rows from your CSV:</h4>
          <table // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
            className={`dealers-import-table selection-${intersectionsSelection}`}
            onClick={event => {
              if (event.target instanceof Element) {
                const cell = event.target.closest('.dealers-import-table tr>*');
                if (cell instanceof Element)
                  setIntersectionsSelection(
                    cell.matches('.dealers-import-table tr>*:first-child') ? 'meta' : 'data',
                  );
              }
            }}
          >
            <thead>
              <tr>
                <th>Row #</th>
                <th>DLID</th>
                <th>DLCompany</th>
              </tr>
            </thead>
            <tbody>
              {intersections.map(({ prcoDealerId, DLCompany, rowIndex }, index) => (
                <tr key={index}>
                  <td>{rowIndex + 1}</td>
                  <td>{prcoDealerId}</td>
                  <td>{DLCompany}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
      upsertResult && (
        <div
          key="upsertResult"
          style={{
            fontFamily: 'monospace',
            whiteSpace: 'pre',
            overflow: 'auto',
            padding: '10px',
            margin: '10px',
          }}
        >
          {JSON.stringify(upsertResult, undefined, 4)}
        </div>
      ),
    ];
  }

  if (!content)
    content = (
      <>
        <Dropzone onDrop={onDrop} accept={ACCEPTED_MIME_TYPES}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              style={{
                height: 300,
                borderRadius: 7,
                border: isDragActive ? '1px dashed green' : '1px solid black',
                cursor: 'pointer',
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>Drop .csv file with dealers here</p>
                <p><Button>Select CSV</Button></p>

                CSV can contain columns with following headers:
                <table // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
                  className="dealers-import-table"
                  onClick={e => {e.stopPropagation()}}
                >
                    <thead>
                    <tr>
                        <th>DLID</th>
                        <th>DLCompany</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>ZIP</th>
                        <th>DLEff</th>
                        <th>DLExp</th>
                    </tr>
                    </thead>
                    <tbody/>
                </table>
                DLID column is required. Unknown columns will be ignored.
              </div>
            </div>
          )}
        </Dropzone>

        <Checkbox
          style={{ zoom: 2 }}
          checked={confirmOverwrite}
          onChange={() => setConfirmOverwrite(!confirmOverwrite)}
        >
          Allow overwrite
        </Checkbox>
        {confirmOverwrite && <p>Existing dealers will be updated.</p>}
      </>
    );

  return (
    <Grid>
      <Row>
        <Col mdOffset={2} md={8}>
          {content}
        </Col>
      </Row>
    </Grid>
  );
};

export const AdminDealersImport = connect()(DealersImport);
