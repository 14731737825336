import React, { SelectHTMLAttributes } from 'react';
import { Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import { FieldProps } from 'formik';
import InputError, { getFieldValidationState } from './InputError';

type OptionValue = string | number | undefined | Readonly<string[]>;

type SelectProps<T, Values> = FieldProps<T, Values> &
  Omit<SelectHTMLAttributes<HTMLSelectElement>, 'children' | 'onChange'> & {
    label: string;
    options: { value: T; label: string }[];
    onChange(value: T | boolean): void;
  };

export default function ValidationSelect<T = OptionValue, Values = {}>({
  label,
  options,
  field,
  form: { errors, touched, setFieldTouched, setFieldValue },
  meta,
  ...inputProps
}: SelectProps<T, Values>) {
  const { name } = field;
  const fieldError = errors[name];
  const fieldTouched = touched[name];

  const onFieldChange = e => {
    const value = e.target.value;
    const { onChange } = inputProps;
    // handle yes/no values as "true"/"false"

    if (value === 'true' || value === 'false') {
      const trueFalseValue = value === 'true';
      setFieldValue(field.name, trueFalseValue);
      onChange?.(trueFalseValue);
      return;
    }
    onChange?.(value);
    setFieldValue(field.name, value);
  };

  const onFieldBlur = () => {
    setFieldTouched(field.name, true);
  };

  return (
    <Row>
      <Col xs={3}>
        <label htmlFor={name}>{label}</label>
      </Col>
      <Col xs={9}>
        <div>
          <FormGroup
            controlId={name}
            validationState={getFieldValidationState({ fieldError, fieldTouched })}
          >
            {/*  @ts-expect-error */}
            <FormControl
              {...field}
              {...inputProps}
              onChange={onFieldChange}
              onBlur={onFieldBlur}
              id={name}
              componentClass="select"
            >
              {options.map(({ value, label }) => (
                // eslint-disable-next-line max-len
                <option value={String(value)} key={label}>
                  {label}
                </option>
              ))}
            </FormControl>

            <InputError fieldName={name} />
          </FormGroup>
        </div>
      </Col>
    </Row>
  );
}
