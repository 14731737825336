import React, { InputHTMLAttributes } from 'react';
import DateTime from 'react-datetime';
import { Col, FormGroup, Row } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import { FieldProps } from 'formik';
import InputError, { getFieldValidationState } from './InputError';

type InputProps<V, Values> = {
  label: string;
  pickerProps: DateTime.DatetimepickerProps;
} & FieldProps<V, Values> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'children'>;

export const DATE_FORMAT = 'MM/DD/YYYY';

export default function ValidationDatePicker<V = string | Date | Moment, Values = {}>({
  label,
  pickerProps,
  field,
  style,
  form: { errors, touched, setFieldTouched, setFieldValue },
}: // meta,
InputProps<V, Values>): React.ReactElement {
  const { name } = field;
  const fieldError = errors[name];
  const fieldTouched = touched[name];

  const onFieldChange = value => {
    // if the date field isn't in a valid date format,
    // react-datetime's onChange handler returns a string
    // otherwise it returns a moment object
    // this is why we can't override DateTime's onChange
    // prop with Formik's field.onChange
    if (value instanceof moment) {
      const parsedDateValue = moment(value).toDate(); // .format(DATE_FORMAT);
      setFieldValue(field.name, parsedDateValue);
      return;
    }

    if (value != null && value.length === DATE_FORMAT.length) {
      // attempt to parse a typed input
      try {
        const parsedDateValue = moment(value, DATE_FORMAT);
        setFieldValue(field.name, parsedDateValue.toDate());
      } catch (err) {
        //   noop
      }
    }
    // we dont pass along the random string inputs - as they might be erroneous inputs to the consumer
  };

  const onFieldBlur = () => {
    setFieldTouched(field.name, true);
  };

  return (
    <Row>
      <Col xs={3}>
        <label htmlFor={name}>{label}</label>
      </Col>
      <Col xs={9}>
        <div>
          <FormGroup
            controlId={name}
            validationState={getFieldValidationState({ fieldTouched, fieldError })}
          >
            {/*  @ts-expect-error */}
            <DateTime
              timeFormat={false}
              dateFormat={DATE_FORMAT}
              {...field}
              {...pickerProps}
              onChange={onFieldChange}
              onBlur={onFieldBlur}
              inputProps={{
                id: name,
                placeholder: `${DATE_FORMAT}`,
              }}
            />
            <InputError fieldName={name} />
          </FormGroup>
        </div>
      </Col>
    </Row>
  );
}
