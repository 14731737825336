import React, { TextareaHTMLAttributes } from 'react';
import { FieldProps } from 'formik';
import { Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import InputError, { getFieldValidationState } from './InputError';

type InputProps<V, Values> = {
  label: string;
} & FieldProps<V, Values> &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'children'>;

export default function ValidationTextarea<V, Values>({
  label,
  field,
  form: { errors, touched, isSubmitting },
  meta,
  style,
  ...inputProps
}: InputProps<V, Values>) {
  const { name } = field;
  const fieldError = errors[name];
  const fieldTouched = touched[name];
  return (
    <Row>
      <Col xs={3}>
        <label htmlFor={name}>{label}</label>
      </Col>
      <Col xs={9}>
        <div>
          <FormGroup
            controlId={name}
            validationState={getFieldValidationState({ fieldError, fieldTouched })}
          >
            {/*  @ts-expect-error */}
            <FormControl {...field} {...inputProps} componentClass="textarea" />
            <InputError fieldName={name} />
          </FormGroup>
        </div>
      </Col>
    </Row>
  );
}
